import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/certificate',
    name: 'certificate',
    component: () => import(/* webpackChunkName: "about" */ '../views/certificate.vue'),
    meta:{
      title: '质量区块链证书',
    }
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue'),
    meta:{
      title: '溯源',
    }
  },
  {
    path: '/scan',
    name: 'scan',
    component: () => import(/* webpackChunkName: "about" */ '../views/scan.vue'),
    meta:{
      title: '扫码次数',
    }
  },
  {
    path: '/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
    meta:{
      title: '溯源',
    }
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "about" */ '../views/product.vue'),
    meta:{
      title: '产品质量信息',
    }
  },
  {
    path:'/weihua',
    name:'weihua',
    component:()=>import(/* webpackChunkName: "weihua" */ '../views/weihua/weihua.vue'),
    meta:{
      title:'海关'
    }
  },
  {
    path:'/search',
    name:'search',
    component:()=>import(/* webpackChunkName:"search" */ '../views/search/index.vue' ),
    meta:{
      title:'产品追溯查询'
    },
  },
  {
    path:'/info',
    name:'searchInfo',
    component:()=>import('../views/search/info.vue'),
    meta:{
      title:'产品追溯信息'
    }
  },
  {
    path:'/check',
    name:'check',
    component:()=>import('../views/search/check.vue'),
    meta:{
      title:'防伪查询'
    }
  }
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
